<template>
  <div class="projectsWrap">
    <div class="searchWrap">
      <span class="label"> 模板名称： </span>
      <el-input
        v-model="searchValue"
        clearable
        placeholder="请输入模板名称："
      ></el-input>
      <el-button type="primary" icon="el-icon-search" @click="search"
        >搜索</el-button
      >
    </div>
    <el-table
      :data="tableDataInfo.result"
      style="width: 100%"
      class="projectsTable"
      border
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="c in tableColumns"
        :key="c.prop"
        :label="c.label"
        :width="c.width"
      >
        <template slot-scope="scope">
          <div v-if="c.prop.indexOf('time') > -1">
            {{ dateTransform(scope.row[c.prop]) }}
          </div>

          <el-tag
            :type="TEMPLATES_STATUS[scope.row[c.prop]].class"
            v-else-if="c.prop == 'status'"
          >
            {{ TEMPLATES_STATUS[scope.row[c.prop]].text }}
          </el-tag>
          <div v-else-if="c.prop == 'project_img'">
            <img
              class="projectImg"
              v-if="scope.row[c.prop]"
              :src="scope.row[c.prop]"
            />
          </div>
          <div
            class="operations"
            v-else-if="
              c.prop == 'operations' && scope.row.status == '0' && isManager
            "
          >
            <el-button size="mini" type="primary" @click="agree(scope.row.id)">
              同意
            </el-button>

            <el-button
              size="mini"
              type="danger"
              @click="showDialog(scope.row.id)"
            >
              驳回
            </el-button>
          </div>
          <span style="margin-left: 10px" v-else>{{ scope.row[c.prop] }}</span>
        </template>
      </el-table-column>
    </el-table>

    <Pagination
      v-if="tableDataInfo.total > 8"
      :handleSizeChange="
        (v) => {
          this.getTableData({ page_size: v });
        }
      "
      :handleCurrentPageChange="
        (v) => {
          this.getTableData({ page_num: v });
        }
      "
      :total="tableDataInfo.total"
    />

    <el-dialog
      title="驳回原因"
      :visible="dialogVisible"
      width="30%"
      :center="true"
      custom-class="newProject"
      :before-close="
        () => {
          this.dialogVisible = false;
        }
      "
    >
      <div>
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="reason"
        />
      </div>
      <span slot="footer" class="shareFooter">
        <el-button type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import { TEMPLATES_STATUS } from "@/constants/editor.js";
import Pagination from "@/components/Pagination";

export default {
  data() {
    return {
      tableColumns: [
        {
          label: "模板名称",
          prop: "project_name",
        },
        {
          label: "模板封面",
          prop: "project_img",
        },
        {
          label: "提交人",
          prop: "create_by_name",
        },
        {
          label: "提交描述",
          prop: "remark",
        },
        {
          label: "状态",
          prop: "status",
        },
        {
          label: "驳回原因",
          prop: "check_remark",
        },
        {
          label: "提交时间",
          prop: "create_time",
        },
        {
          label: "处理时间",
          prop: "update_time",
        },
        {
          label: "操作",
          prop: "operations",
          width: "160",
        },
      ],
      tableDataInfo: {},
      searchValue: "",
      dialogVisible: false,
      activeRowId: "",
      reason: "",
      TEMPLATES_STATUS,
    };
  },
  components: {
    Pagination,
  },
  mounted() {
    this.getTableData({});
  },
  computed: {
    ...mapState({
      isManager: (state) => state.loginAccountInfo.is_manage,
    }),
  },
  methods: {
    handleSizeChange(v) {},
    handleCurrentPageChange(v) {},
    getTableData({ project_name, page_num = 1, page_size = 8 }) {
      this.$request
        .getTemplatesList({ page_num, page_size, project_name })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.tableDataInfo = res.data || {};
        });
    },
    agree(id) {
      this.audit({ id, status: "1" });
    },
    submit() {
      if (this.reason.trim() == "") {
        this.$message.warning("请输入驳回原因");
        return;
      }
      this.audit({
        id: this.activeRowId,
        status: "2",
        check_remark: this.reason,
      });
    },
    audit({ id, status, check_remark }) {
      this.$request
        .updateTemplateStatus({
          id: id,
          status,
          check_remark,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.$message.success("审批成功");
          this.getTableData({});
          this.dialogVisible = false;
          this.reason = "";
        });
    },
    showDialog(id) {
      this.dialogVisible = true;
      this.activeRowId = id;
    },
    search() {
      this.getTableData({
        project_name: this.searchValue,
      });
    },
    dateTransform(d) {
      if (!d) return;
      return moment(d).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>

<style lang='scss'>
.projectsWrap {
  padding: 0 24px 24px;
  background-color: #fff;
  .searchWrap {
    display: flex;
    padding: 16px 0;
    align-items: center;

    .el-input {
      width: 200px !important;
    }
    .el-button {
      margin-left: 16px;
    }
  }
  .projectsTable {
    .normal {
      color: #67c23a;
    }
    .operations {
      display: flex;
      justify-content: space-evenly;
    }
    .projectImg {
      width: 100px;
      height: 60px;
    }
    .unaudit {
      color: #333;
    }
    .passed {
      color: green;
    }
    .refused {
      color: #e33;
    }
    .forbid {
      color: #f56c6c;
    }
  }
}
.newProject {
  .el-dialog__body {
    padding: 12px !important;
  }
  .level {
    padding: 6px 60px;

    display: flex;
    justify-content: space-between;
    font-size: 16px;
    .create {
      color: #409eff;
      cursor: pointer;
    }
  }
}
</style>